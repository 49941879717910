


















import Vue from 'vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import HostPaymentService from '@/services/owner/HostPaymentService';
import { MetaInfo } from 'vue-meta';
import { mapState } from 'vuex';

export default Vue.extend({
  components: { Jumbotron },
  data() {
    return {
      loading: true,
      data: {} as { stripeAccountId: String; stripeAccountLinkedAt: String },
    };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
    var accountLinkCompleted = this.$route.query.accountLinkCompleted as string;
    if (accountLinkCompleted == 'true') {
      HostPaymentService.completedAccountLink();
    }
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostPaymentSettingsOverview.meta.title').toString(),
    };
  },
  computed: {
    ...mapState('StatusStoreModule', ['status']),
    hasActiveSubscription(): boolean {
      return this.status.subscriptedModules.includes('AutomaticPaymentForPrepayment');
    },
    hasStripeAccount(): boolean {
      return !!this.data.stripeAccountId;
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostMoneyTransactionIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      HostPaymentService.getSettings().then((res) => {
        this.loading = false;
        this.data = res.value;
      });
    },

    createStripeAccount() {
      this.loading = true;
      HostPaymentService.createStripeAccount().then(() => {
        HostPaymentService.createStripeAccountLink().then((res: { value: { redirectUrl: string } }) => {
          window.location.href = res.value.redirectUrl;
        });
      });
    },

    goToStripeAccountLink() {
      HostPaymentService.createStripeAccountLink().then((res: { value: { redirectUrl: string } }) => {
        window.location.href = res.value.redirectUrl;
      });
    },
  },
});
