import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/Payment/';

class HostPaymentService {
  completedAccountLink() {
    return ApiService.post(`${API_URL}CompletedStripeAccountLink`, {});
  }
  createStripeAccountLink() {
    return ApiService.post(`${API_URL}CreateStripeAccountLink`, {});
  }

  createStripeAccount() {
    return ApiService.post(`${API_URL}CreateStripeAccount`, {});
  }

  getSettings() {
    return ApiService.get(`${API_URL}Settings`);
  }
}

export default new HostPaymentService();
